import React, { Fragment } from "react";
import CommonPara from "../Common-para";
import Nav from "../Nav";
import RunnningText from "../RunningText";
import CardRight from "../CardRight";
import CardLeft from "../CardLeft";
import Footer from "../Footer";
import JoinToday from "../JoinToday";
import FitnessSwiper from "../FitnessSwiper";
import { Parallax } from "react-parallax";
import ArrowAnimation from "../ArrowAnimation";
import YellowLine from "../../assets/coming_soon/yellow-grunge-line.svg";
import { RockHomepage, RockPerformancePage } from "../Images/rockImages";

const Performance = () => {
  return (
    <>
      <div className="fitness">
        <div className="fitness-container">
          <div className="hero-container">
            <Nav />
            <section className="hero-section row">
              <div className="col-xl-7 col-lg-8 col-md-8 col-10 mx-auto">
                <div className="hero-text">
                  <p>ROCK PERFORMANCE</p>
                  <h1>
                    First Class <br /> Personal <br />
                    Training
                  </h1>
                  <div className="hero-join-today">
                    <ArrowAnimation
                      text={"START NOW"}
                      isAnimate={true}
                      isModal={true}
                    />
                  </div>
                </div>
                <div className="hero-img">
                  <div className="mobile-img">
                    <div className="set-height">
                      <img
                        src={RockHomepage.RockPerformance.url}
                        alt={RockHomepage.RockPerformance.alt}
                      />
                    </div>
                  </div>

                  <div className="desktop-img">
                    <Parallax
                      bgImage={RockHomepage.RockPerformance.url}
                      bgImageAlt={RockHomepage.RockPerformance.alt}
                      strength={120}
                    >
                      <div className="set-height"></div>
                    </Parallax>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="paragraph-section common-padding">
            <CommonPara
              text={
                "Receive a Free Personal Training Orientation with one of our world class trainers."
              }
            />
          </section>

          <section className="swiper-section common-padding">
            <div className="row">
              <div className="col-xl-11 col-lg-11 col-md-11 col-11 mx-auto mr-0">
                <div className="swiper">
                  <FitnessSwiper
                    image={RockPerformancePage.Caraousal}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="fit-fierce-section common-padding">
            <RunnningText text={"PUSH YOUR LIMITS"} />
          </section>

          <section className="right-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto ">
                <CardRight
                  text={<Fragment>Our Trainers</Fragment>}
                  para={
                    "ROCK’s personal trainers are result oriented. Our trainers are acutely aware of each and every client’s needs and ensure you are always progressing while on your journey to bettering yourself."
                  }
                  image={RockPerformancePage.OurTrainers.url}
                  bgAlt={RockPerformancePage.OurTrainers.alt}
                />
              </div>
            </div>
          </section>

          <section className="left-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <CardLeft
                  text={
                    <Fragment>
                      Book a <br />
                      Session Now
                    </Fragment>
                  }
                  content={Sessions}
                  image={RockPerformancePage.BookSession.url}
                  bgAlt={RockPerformancePage.BookSession.alt}
                />
              </div>
            </div>
          </section>

          <section className="join-today-section common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <JoinToday />
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Performance;

const Mentorship = (
  <>
    <div className="m-title">
      <h5>What you get:</h5>
    </div>
    <div className="m-list">
      <ul>
        <li>Individual goal setting and assessment session</li>
        <li>3 Fully Customized Semi-Private Training Sessions</li>
        <li>Nutrition Plan and Body Composition Scan</li>
        <li>Unlimited Fitness & Lifestyle Membership</li>
      </ul>
    </div>
    <div className="m-para">
      <p>
        After your assessment, you and your coach will sit down and formulate a
        long term plan for success.
      </p>
    </div>
    <div className="m-link">
      <div className="cost">
        <h5>Cost: FREE</h5>
        <img src={YellowLine} alt="Yellow Line" />
      </div>
      <div className="buy-link">
        <ArrowAnimation
          text={"CALL NOW"}
          link={"tel:+1-718-204-1400"}
          isAnimate={true}
        />
      </div>
    </div>
  </>
);

const Sessions = (
  <>
    <div className="s-para">
      <p>
        Our expert coaches will build you a custom play suitable for your goals
        and needs.
      </p>
    </div>
    {/* <div className="s-rate">
      <h5>From $249.99/Month*</h5>
      <p>*Conditions Apply</p>
    </div> */}
    <div className="buy-link">
      <ArrowAnimation text={"BUY NOW"} isAnimate={true} isModal={true} />
    </div>
  </>
);
