import React from "react";
import Discount from "../assets/coming_soon/discount-icon.svg";
import ArrowAnimation from "./ArrowAnimation";

const PackagesBanner = ({
  image,
  title,
  cardTitle,
  cardPara,
  price,
  displaDiscountIcon,
  linkText,
  srcLink,
  target,
  isModal,
}) => {
  return (
    <>
      <div className="banner-container">
        <div className="info" style={{ backgroundImage: `url(${image})` }}>
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-6 col-10 mx-auto">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-12 mx-auto">
                  <div className="title">
                    <h1>{title}</h1>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-12 mx-auto">
                  <div className="card">
                    <div className="card-content">
                      <div>
                        <div className="card-title">
                          <h3>{cardTitle}</h3>
                        </div>
                        <div className="price">
                          <p>{price}</p>
                        </div>
                        <div className="card-para">
                          {displaDiscountIcon ? (
                            <div className="discount-img">
                              <img src={Discount} alt="Rock Discount Image" />
                            </div>
                          ) : (
                            <div></div>
                          )}

                          <p>{cardPara}</p>
                        </div>
                      </div>
                      <div className="inquiry">
                        <ArrowAnimation
                          text={linkText}
                          link={srcLink}
                          target={target}
                          isModal={isModal}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="condition">*Conditions Apply</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagesBanner;
