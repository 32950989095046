import React from "react";
import ContactForm from "./ContactForm";

function Model() {
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="staticBackdropLabel">
            Start your Fitness and <br /> Personal Training Journey Today
          </h4>
          <h5 className="modal-subtitle">Fill out the form to get started</h5>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Model;
