import React from "react";
import SocialFollows from "./SocialFollows";
import { Link } from "react-router-dom";
import Rock from "../assets/coming_soon/rock-logo-black.svg";
import ArrowAnimation from "./ArrowAnimation";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="row">
        <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
          <div className="footer-Wraper">
            <section className="footer-logo">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-6">
                  <div className="rock-logo">
                    <Link to="/">
                      <img src={Rock} alt="Rock" />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-6">
                  <div className="footer-logo-text">
                    <ArrowAnimation
                      text={<>START NOW</>}
                      isAnimate={true}
                      isModal={true}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="footer-all">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-7 col-6">
                  <div className="footer-links">
                    <div className="footer-title">ROCK</div>
                    <Link to="/fitness">Fitness</Link>
                    <Link to="/facilities">Our Facility</Link>
                    <Link to="/performance">Performance</Link>
                    <Link to="/packages">Packages</Link>
                    <Link to="/classes">Classes</Link>
                    <Link to="/rockbar">Rock Bar</Link>
                    <a data-toggle="modal" data-target="#staticBackdrop">
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-6">
                  <div className="footer-links get-in-touch">
                    <div className="footer-title">GET IN TOUCH</div>
                    <div>
                      <p>
                        <a href="tel:+1-718-204-1400">+1 718 204 1400</a>
                      </p>
                    </div>
                    <div>
                      <p>
                        <a href="mailto:info@rock-gyms.com">
                          info@rock-gyms.com
                        </a>
                      </p>
                    </div>
                    <div>
                      <a
                        href="https://goo.gl/maps/n73BS6jGVggsRYkt8"
                        target="_blank"
                      >
                        <p>
                          22-06 31st Street,
                          <br />
                          Astoria,NY - 11105 <br />
                          United States
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <div className="row">
                    <div className="col-md-12 col-6 footer-title">
                      <div className="footer-links connect-us">
                        <div className="footer-title">CONNECT</div>
                        <div className="social">
                          <SocialFollows />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-6">
                      <div className="footer-links connect-us">
                        <div className="footer-title">DOWNLOAD OUR APP</div>
                        <div className="app-links">
                          <a href="https://apps.apple.com/us/app/rock-gyms/id1541631196" target="_blank">
                            <img className="img-fluid" src="https://res.cloudinary.com/rock-gyms-com/image/upload/v1695216670/download-on-the-app-store-apple-logo-svgrepo-com_ccqmyf.svg" alt="App Store Link" />
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.migym.com.Rock_Gyms&pli=1" target="_blank">
                          <img className="img-fluid" src="https://res.cloudinary.com/rock-gyms-com/image/upload/v1695216670/google-play-badge-logo-svgrepo-com_ku4suy.svg" alt="Play Store Link" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-10">
                  <div className="footer-links">
                    <div className="footer-copywrite">
                      <p>© 2020, All Rights Reserved.</p>
                      <div className="inline">
                        <p>Site by </p> &nbsp;
                        <a href="https://littleplanet.studio/" target="_blank">
                          Little Planet
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
