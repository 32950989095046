import React from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFlip,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/effect-flip/effect-flip.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFlip]);

const HomeSwiper = ({images}) => {
  return (
    <>
      <Swiper slidesPerView={1} spaceBetween={900} navigation>
        {
          images && images[0] && images.map(image => (
            <SwiperSlide>
              <img src={image.url} alt={image.alt} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
};

export default HomeSwiper;
