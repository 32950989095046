import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./components/pages/Home";
import { Redirect, Route, Switch } from "react-router-dom";
import Fitness from "./components/pages/Fitness";
import Packages from "./components/pages/Packages";
import Header from "./components/Header";
import Performance from "./components/pages/Performance";
import Safety from "./components/pages/Safety";
import Classes from "./components/pages/Classes";
import ScrollToTop from "./components/ScrollToTop";
import Thankyou from "./components/pages/Thankyou";
import NotificationTag from "./components/NotificationTag";
import { NotificationProvider } from "./components/context/NotificationContext";
import Oops from "./components/pages/Oops";
import Facility from "./components/pages/Facility";
import RockBar from "./components/pages/RockBar";

function App() {
  return (
    <>
      <NotificationProvider>
        {/* <NotificationTag /> */}
        <Header />
      </NotificationProvider>
      <div className="scroller">
        <div>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/fitness" component={Fitness} />
              <Route exact path="/facilities" component={Facility} />
              <Route exact path="/performance" component={Performance} />
              <Route exact path="/packages" component={Packages} />
              <Route exact path="/classes" component={Classes} />
              <Route exact path="/thankyou" component={Thankyou} />
              <Route exact path="/oops" component={Oops} />
              <Route exact path="/rockbar" component={RockBar} />
              <Redirect to="/" />
            </Switch>
          </ScrollToTop>
        </div>
      </div>
    </>
  );
}

export default App;
