import React, { Fragment } from "react";
import CommonPara from "../Common-para";
import Nav from "../Nav";
import RunnningText from "../RunningText";
import CardRight from "../CardRight";
import CardLeft from "../CardLeft";
import Footer from "../Footer";
import JoinToday from "../JoinToday";
import FitnessSwiper from "../FitnessSwiper";
import { Parallax } from "react-parallax";
import ArrowAnimation from "../ArrowAnimation";
import { RockHomepage, RockFitnessPage } from "../Images/rockImages";

const Fitness = () => {
  return (
    <>
      <div className="fitness">
        <div className="fitness-container">
          <div className="hero-container">
            <Nav />
            <section className="hero-section row">
              <div className="col-xl-7 col-lg-8 col-md-8 col-10 mx-auto">
                <div className="hero-text">
                  <p>ROCK FITNESS</p>
                  <h1>
                    World Class <br /> Fitness <br /> Facility
                  </h1>
                  <div className="hero-join-today">
                    <ArrowAnimation
                      text={"START NOW"}
                      isAnimate={true}
                      isModal={true}
                    />
                  </div>
                </div>
                <div className="hero-img">
                  <div className="mobile-img">
                    <div className="set-height">
                      <img
                        src={RockFitnessPage.Header.url}
                        alt={RockFitnessPage.Header.alt}
                      />
                    </div>
                  </div>

                  <div className="desktop-img">
                    <Parallax
                      bgImage={RockFitnessPage.Header.url}
                      bgImageAlt={RockFitnessPage.Header.alt}
                      strength={120}
                    >
                      <div className="set-height"></div>
                    </Parallax>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="paragraph-section common-padding">
            <CommonPara
              text={
                " Our space was acutely designed to inspire our clientele to be more and do more. ROCK’s facility is furnished with state of the art equipment assuring that our clients have the best so they can be their best."
              }
            />
          </section>

          <section className="swiper-section common-padding">
            <div className="row">
              <div className="col-xl-11 col-lg-11 col-md-11 col-11 mx-auto mr-0">
                <div className="swiper">
                  <FitnessSwiper
                    image={[
                      {
                        url: RockFitnessPage.RockFitness1.url,
                        alt: RockFitnessPage.RockFitness1.alt,
                      },
                      {
                        url: RockFitnessPage.RockFitness2.url,
                        alt: RockFitnessPage.RockFitness2.alt,
                      },
                      {
                        url: RockFitnessPage.RockFitness3.url,
                        alt: RockFitnessPage.RockFitness3.alt,
                      },
                      {
                        url: RockFitnessPage.RockFitness4.url,
                        alt: RockFitnessPage.RockFitness4.alt,
                      },
                      {
                        url: RockFitnessPage.RockFitness5.url,
                        alt: RockFitnessPage.RockFitness5.alt,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="fit-fierce-section common-padding">
            <RunnningText text={"FIT FIERCE FABULOUS"} />
          </section>

          <section className="left-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <CardLeft
                  text={<Fragment>Energy</Fragment>}
                  para={
                    "In the heart of Queens, New York, our club is designed to reenergize our clients and allow them to get the most out of their lifestyle while capturing the culture of the surrounding neighborhood."
                  }
                  image={RockFitnessPage.Energy.url}
                  bgAlt={RockFitnessPage.Energy.alt}
                />
              </div>
            </div>
          </section>

          <section className="right-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto ">
                <CardRight
                  text={<Fragment>Performance</Fragment>}
                  para={
                    "A new episode of high-performance, ROCK has expanded its focus and brought in unparalleled person trainers to create and set out a plan uniquely tailored to your goals. ROCK’s personal training formula is designed to drive you to set out and achieve those goals."
                  }
                  image={RockFitnessPage.Performance.url}
                  bgAlt={RockFitnessPage.Performance.alt}
                  link={
                    <>
                      KNOW MORE <div className="arrow-position ">↗</div>
                    </>
                  }
                  href={"/performance"}
                />
              </div>
            </div>
          </section>

          {/* <section className="left-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <CardLeft
                  text={
                    <Fragment>
                      Health & <br />
                      Wellness Strategy
                    </Fragment>
                  }
                  para={
                    "ROCK has worked hard at formulating a comprehensive COVID-19 reopening strategy. ROCK recognizes the importance of health and wellness, which is why we have gone above and beyond to ensure the health, safety, and cleanliness of our facility."
                  }
                  image={RockHomepage.RockSafety.url}
                  bgAlt={RockHomepage.RockSafety.alt}
                  link={
                    <>
                      KNOW MORE <div className="arrow-position ">↗</div>
                    </>
                  }
                  href={"/safety"}
                />
              </div>
            </div>
          </section> */}

          <section className="join-today-section common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <JoinToday />
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Fitness;
