import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Nav from "../Nav";

function Oops() {
  return (
    <div>
      <div className="thankyou">
        <div className="thankyou-container">
          <div className="hero-container">
            <Nav />
            <section className="hero-section row">
              <div className="col-xl-9 col-lg-8 col-md-8 col-10 mx-auto">
                <div className="hero-text">
                  <h1>Oops</h1>
                  <p>
                    Something went wrong. <br /> please try again after
                    sometime.
                  </p>
                  <Link to="/">Go back to Home Page.</Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Oops;
