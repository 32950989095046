import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = ({ history }) => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    // msg: "",
  });

  const [select1, setSelect1] = useState();
  const [select2, setSelect2] = useState();

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState();

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const InputEvent = (event) => {
    const { name, value } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    let errors = [];

    //firstname
    if (data.firstname === "") {
      errors.push("firstname");
    }

    //lastname
    if (data.firstname === "") {
      errors.push("lastname");
    }

    //email
    const expression = /\S+@\S+/;
    let validEmail = expression.test(String(data.email).toLowerCase());

    if (!validEmail) {
      errors.push("email");
    }

    //Phone
    const phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    let validPhone = phoneno.test(String(data.phone).toLowerCase());

    if (!validPhone) {
      errors.push("phone");
    }

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    } else {
      // ============= EMAIL JS ==============

      emailjs
        .sendForm(
          "service_pq7b7rp",
          "template_igy1o3w",
          event.target,
          "user_xB67ps1lkONrBB0SpGfrA"
        )
        .then(
          (result) => {
            console.log(result.text);
            setData({
              firstname: "",
              lastname: "",
              phone: "",
              email: "",
              msg: "",
            });

            setSuccess(
              "Thank you for connecting us, we will reach you back soon."
            );
            // history.push("/thankyou");
            window.location = "/thankyou";
          },
          (error) => {
            console.log(error.text);
            window.location = "/oops";
          }
        );

      // ============= EMAIL JS END ==============

      //  ================== FIREBASE ===================

      // projectFirestore
      //   .collection("contacts")
      //   .add({
      //     firstname: data.firstname,
      //     lastname: data.lastname,
      //     email: data.email,
      //     phone: data.phone,
      //     interestedIn: select1,
      //     lookingAt: select2,
      //     // message: data.msg,
      //   })
      //   .then((result) => {
      //     console.log(result.text);
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //   });

      //  ================== FIREBASE END ===================
    }
  };

  return (
    <>
      <div className="contact-form-div">
        <div className="row">
          <div className="col-xl-11 col-lg-12 col-md-12 col-12 contact-form">
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="field-wrapper">
                    <div className="first-name">
                      <label className="form-label">First Name*</label>
                      <input
                        type="text"
                        className={
                          hasError("firstname")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="firstname"
                        value={data.firstname}
                        onChange={InputEvent}
                        placeholder="Enter your first name"
                      />
                      <div
                        className={
                          hasError("firstname") ? "inline-errormsg" : "hidden"
                        }
                      >
                        Please enter first name
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="field-wrapper">
                    <div className="last-name">
                      <label className="form-label">Last Name*</label>
                      <input
                        type="text"
                        className={
                          hasError("lastname")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="lastname"
                        value={data.lastname}
                        onChange={InputEvent}
                        placeholder="Enter your last name"
                      />
                      <div
                        className={
                          hasError("lastname") ? "inline-errormsg" : "hidden"
                        }
                      >
                        Please enter last name
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field-wrapper">
                <label className="form-label">Email*</label>
                <input
                  type="email"
                  className={
                    hasError("email")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="email"
                  value={data.email}
                  onChange={InputEvent}
                  placeholder="name@example.com"
                />
                <div
                  className={hasError("email") ? "inline-errormsg" : "hidden"}
                >
                  Please enter a valid email
                </div>
              </div>

              <div className="field-wrapper">
                <label className="form-label">Phone*</label>
                <input
                  type="number"
                  className={
                    hasError("phone")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="phone"
                  value={data.phone}
                  onChange={InputEvent}
                  placeholder="Enter your phone"
                />
                <div
                  className={hasError("phone") ? "inline-errormsg" : "hidden"}
                >
                  Please enter a valid phone No.
                </div>
              </div>

              <div className="field-wrapper">
                <label className="form-label">
                  What services are you interested in?*
                </label>
                <select
                  id="inputState"
                  value={select1}
                  name="select1"
                  className="form-control"
                  onChange={(e) => {
                    setSelect1(e.target.value);
                  }}
                  required
                >
                  <option disabled value="" selected hidden>
                    Please select an option
                  </option>
                  <option value="Personal Training(One-on-one)">
                    Personal Training(One-on-one)
                  </option>
                  <option value="Semi-Private Personal Training(2-6 Per Session)">
                    Semi-Private Personal Training(2-6 Per Session)
                  </option>
                  <option value="Gym Membership">Gym Membership</option>
                </select>
              </div>

              <div className="field-wrapper">
                <label className="form-label">
                  What are you looking to accomplish?*
                </label>
                <select
                  id="inputState"
                  value={select2}
                  name="select2"
                  className="form-control"
                  onChange={(e) => {
                    setSelect2(e.target.value);
                  }}
                  required
                >
                  <option disabled value="" selected hidden>
                    Please select an option
                  </option>
                  <option value="Lose Weight" defaultValue>
                    Lose Weight
                  </option>
                  <option value="Get Stronger">Get Stronger</option>
                  <option value="Reduce Stress">Reduce Stress</option>
                  <option value="Find time to get my workouts in">
                    Find time to get my workouts in
                  </option>
                </select>
              </div>

              {/* <div className="field-wrapper">
                <label for="exampleFormControlTextarea1" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  name="msg"
                  value={data.msg}
                  onChange={InputEvent}
                  rows="4"
                ></textarea>
              </div> */}
              <div className="col-12">
                <div className="button-wrapper">
                  <button className="btn btn-outline-dark" type="submit">
                    SEND
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactForm;
