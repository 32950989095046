import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Hamburger from "./Hamburger";
import $ from "jquery";
import MutationObserver from "mutation-observer";
import { NotificationContext } from "./context/NotificationContext";

const Header = ({ history }) => {
  // State of our Menu
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: true,
  });

  const [isMenuButtonVisible, setIsMenuButtonVisible] = useState(false);

  // State of our button
  const [disabled, setDisabled] = useState(false);

  const [notification, setNotification] = useContext(NotificationContext);

  //Use Effect
  useEffect(() => {
    setTimeout(() => {
      if (document.body.scrollHeight == document.body.offsetHeight) {
        var observer = new MutationObserver(function (mutations) {
          let scrollValue = -+mutations[0].target.style.cssText
            .split(",")[1]
            .split("px")[0];
          if (scrollValue < 50) {
            showMenu(false);
          } else {
            showMenu(true);
          }
        });
        var target = document.getElementsByClassName("scroll-content")[0];
        observer.observe(target, {
          attributes: true,
          attributeFilter: ["style"],
        });
      } else {
        $(window).on("scroll", function (e) {
          if (window.scrollY < 100) {
            showMenu(false);
          } else {
            showMenu(true);
          }
        });
      }
    }, 500);

    //Listening for page changes.
    history.listen(() => {
      setState({ clicked: false, menuName: true });
    });
  }, [history]);

  const showMenu = (flag) => {
    setIsMenuButtonVisible(flag);
  };

  const handleNotification = () => {
    if (notification === false && window.scrollY < 20) {
      setNotification(true);
    } else {
      setTimeout(() => {
        setNotification(false);
      }, 500);
    }
  };

  // Toggle menu
  const handleMenu = () => {
    disableMenu();
    handleNotification();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: false,
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: true,
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: false,
      });
    }
    disableScroll();
  };

  //Disable Scroll When menu Button is Clicked
  const disableScroll = () => {
    if (state.menuName == false) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
    <header>
      <div className="wrapper">
        <div className="menu">
          <div className="mobile-menu">
            <button
              disabled={disabled}
              onClick={handleMenu}
              className={state.menuName ? "openMenu" : "closeMenu"}
              id="menuButton"
            ></button>
          </div>
          <div className="desktop-menu">
            <button
              style={
                isMenuButtonVisible
                  ? {
                      visibility: "visible",
                      opacity: 1,
                      transform: "translateY(0px)",
                    }
                  : {
                      visibility: "hidden",
                      opacity: 0,
                      transform: "translateY(-100px)",
                    }
              }
              disabled={disabled}
              onClick={handleMenu}
              className={state.menuName ? "openMenu" : "closeMenu"}
              id="menuButton"
            ></button>
            <div></div>
          </div>
        </div>
      </div>
      <Hamburger state={state} handleMenu={handleMenu} />
    </header>
  );
};

export default withRouter(Header);
