import React from "react";

function ArrowAnimation({ text, isModal = false, link }) {
  return (
    <>
      {isModal ? (
        <div className="arrow-animation">
          <a data-toggle="modal" data-target="#staticBackdrop">
            {text}
            <div className="arrow">&nbsp;↗&nbsp;</div>
          </a>
        </div>
      ) : (
        <div className="arrow-animation">
          {link ? (
            <a href={link}>
              {text}
              <div className="arrow">&nbsp;↗&nbsp;</div>
            </a>
          ) : (
            <a
              href="https://www.joinmyhealthclub.com/Competejol/510512"
              target="_blank"
            >
              {text}
              <div className="arrow">&nbsp;↗&nbsp;</div>
            </a>
          )}
        </div>
      )}
    </>
  );
}

export default ArrowAnimation;
