import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      // window.location.reload();
      // console.log("in router");
      // Scrollbar.destroy(document.getElementsByClassName("scroller")[0]);
      document.body.style.overflow = "auto";
      window.scrollTo(0, 0);
      // document.getElementsByClassName("scroll-content")[0].style.transform =
      //   "translate3d(0px,0px,0px)";

      // document.getElementsByClassName(
      //   "scroll-content"
      // )[0].style.transformOrigin = "0px 0px 0px";
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
