import React from "react";
import YellowLine from "../assets/coming_soon/yellow-grunge-line.svg";
import ArrowAnimation from "./ArrowAnimation";

const JoinToday = () => {
  return (
    <>
      <div className="join-today">
        <h1>
          <ArrowAnimation
            text={
              <>
                START <br className="d-inline d-xl-none d-lg-none d-md-none" />
                NOW
              </>
            }
            isAnimate={true}
            isModal={true}
          />
        </h1>

        <img src={YellowLine} alt="Rock Yellow Line" />
      </div>
    </>
  );
};

export default JoinToday;
