import React from "react";
import { NavLink } from "react-router-dom";
import RockLogo from "../assets/coming_soon/rock-logo.svg";
import Model from "./Model";

const Nav = () => {
  return (
    <div className="navbar-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-11 col-md-11 col-11 mx-auto">
            <nav className="navbar navbar-expand-lg">
              <NavLink className="navbar-brand" to="/">
                <div className="rock-logo">
                  <img src={RockLogo} alt="Rock" />
                </div>
              </NavLink>

              <div
                className="collapse navbar-collapse order-2 order-lg-1"
                id="navbarNav"
              >
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0 menu-list">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      aria-current="page"
                      activeClassName="menu_active"
                      to="/fitness"
                      exact
                    >
                      Fitness
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      aria-current="page"
                      activeClassName="menu_active"
                      to="/facilities"
                      exact
                    >
                      Facility
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="menu_active"
                      to="/performance"
                    >
                      Performance
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="menu_active"
                      to="/packages"
                    >
                      Packages
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="menu_active"
                      to="/classes"
                    >
                      Classes
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="menu_active"
                      to="/rockbar"
                    >
                      Rock Bar
                    </NavLink>
                  </li>
                </ul>
                <div className="d-flex right-button">
                  <button
                    className="nav-link"
                    data-toggle="modal"
                    data-target="#staticBackdrop"
                  >
                    Contact Us
                  </button>
                  <a
                    className="btn"
                    href="https://www.joinmyhealthclub.com/Competejol/510512"
                    target="_blank"
                  >
                    Join Now
                  </a>
                </div>
              </div>
            </nav>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-backdrop="static"
              data-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <Model />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
