import React, { Fragment, useEffect } from "react";
import Footer from "../Footer";
import Nav from "../Nav";
import RockHappiness from "../../assets/coming_soon/rock-happiness.svg";
import DownArrow from "../../assets/coming_soon/down-arrow.svg";
import RunnningText from "../RunningText";
import BeMore from "../../assets/coming_soon/be-more-do-more.svg";
import CardLeft from "../CardLeft";
import CardRight from "../CardRight";
import CommonPara from "../Common-para";
import HomeSwiper from "../HomeSwiper";
import { Link as Scroll } from "react-scroll";
import { RockHomepage } from "../Images/rockImages";
import JoinToday from "../JoinToday";
import ArrowAnimation from "../ArrowAnimation";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Home = () => {
  // const scrollToNextSection = () => {
  //   document.getElementsByClassName("scroll-content")[0].style.transform =
  //     "translate3d(0px,-100vh,0px)";
  // };
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let videoElem = document.querySelector("video");

    ScrollTrigger.create({
      trigger: videoElem,
      start: "top 90%",
      end: "top 10%",
      // markers: true,
      onEnter: () => videoElem.play(),
      onEnterBack: () => videoElem.play(),
      // onLeave: () => videoElem.pause(),
      onLeaveBack: () => videoElem.pause(),
    });
  }, []);

  return (
    <>
      <div className="home">
        <div className="home-container">
          <div className="hero-container">
            <Nav />
            <section className="hero-section">
              <div className="row">
                <div className="col-xl-8 col-lg-12 col-md-12 col-10 mx-auto">
                  {/* ========== For Mobile ============ */}

                  <div className="row mobile-View">
                    <div className="col-11">
                      <div className="smile-img">
                        <img src={RockHappiness} alt="Rock Happiness Image" />
                      </div>
                      <div className="hero-title">
                        <h1>
                          ROCK <br /> FITNESS
                        </h1>
                        <div className="sub-text">
                          <ArrowAnimation
                            text={
                              <>
                                Start your Fitness and Personal Training Journey
                                Today
                              </>
                            }
                            isModal={true}
                          />
                        </div>
                      </div>

                      <div className="down-arrow">
                        <Scroll
                          to="video-section"
                          spy={true}
                          smooth={true}
                          duration={700}
                        >
                          <img src={DownArrow} alt="Rock" />
                        </Scroll>
                        {/* <img src={DownArrow} alt="Rock" /> */}
                      </div>
                    </div>
                  </div>

                  {/* ========== For Mobile END ============ */}

                  {/* ========For DESKTOP ========== */}

                  <div className="row desktop-view">
                    <div className="col-xl-8 col-lg-8 col-md-8 mx-auto">
                      <div className="hero-title">
                        <h1>
                          ROCK <br /> FITNESS <br /> IS OPEN
                        </h1>
                        <div className="sub-text">
                          <ArrowAnimation
                            text={
                              <>
                                Start your Fitness and <br /> Personal Training
                                Journey Today
                              </>
                            }
                            isModal={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 mx-auto">
                      <div className="hero-img">
                        <div className="smile-img">
                          <img src={RockHappiness} alt="Rock Fitness" />
                        </div>
                        <div className="down-arrow">
                          <Scroll
                            to="video-section"
                            spy={true}
                            smooth={true}
                            duration={700}
                          >
                            <img src={DownArrow} alt="Rock Fitness" />
                          </Scroll>
                          {/* <img src={DownArrow} alt="Rock" /> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ========For DESKTOP END ========== */}
                </div>
              </div>
              <section
                className="video-section common-padding"
                id="video-section"
              >
                <div className="row">
                  <div className="col-xl-10 col-10 mx-auto my-auto">
                    <div className="video">
                      <video
                        playsInline
                        className="vid"
                        loop
                        muted
                        poster="https://res.cloudinary.com/rock-gyms-com/image/upload/v1696318779/1%20homepage/a%20section%201/wovzy9xtqvhdl0hlmeh2.png"
                      >
                        <source
                          src="https://res.cloudinary.com/rock-gyms-com/video/upload/q_auto/f_auto/v1696417656/1%20homepage/a%20section%201/e4gnphviznflc0gfwl9w.mp4"
                          type="video/mp4"
                        ></source>
                      </video>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>

          <section className="our-mission-section">
            <RunnningText text={"QUALITY COMMITMENT"} />
          </section>

          <section className="paragraph-section common-padding">
            <CommonPara
              text={
                "Our mission at ROCK is to provide the highest quality fitness and personal training solutions to help hold our community members accountable, keeping them committed to improving their lifestyle."
              }
            />
          </section>

          <section className="slider-section common-padding">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-8 col-10 mx-auto">
                <div className="slider">
                  <HomeSwiper
                    images={RockHomepage.Caraousal}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="fitness-section common-padding">
            <RunnningText text={"MIND BODY PERFORMANCE"} />
          </section>

          <section className="left-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <CardLeft
                  text={
                    <Fragment>
                      World Class <br /> Fitness <br /> Facility
                    </Fragment>
                  }
                  link={
                    <>
                      KNOW MORE <div className="arrow-position ">↗</div>
                    </>
                  }
                  href={"/fitness"}
                  image={RockHomepage.RockFitness.url}
                  bgAlt={RockHomepage.RockFitness.alt}
                />
              </div>
            </div>
          </section>

          <section className="right-card common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto ">
                <CardRight
                  text={
                    <Fragment>
                      First Class <br /> Personal <br /> Training
                    </Fragment>
                  }
                  link={
                    <>
                      KNOW MORE <div className="arrow-position ">↗</div>
                    </>
                  }
                  href={"/performance"}
                  image={RockHomepage.RockPerformance.url}
                  bgAlt={RockHomepage.RockPerformance.alt}
                />
              </div>
            </div>
          </section>

          <section className="join-today-section common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <JoinToday />
              </div>
            </div>
          </section>

          <section className="be-more common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <div className="be-more-img">
                  <img src={BeMore} alt="Rock Be More Do More Image" />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
