const RockHomepage = {
  RockFitness: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696324701/1%20homepage/c%20fitness/mpw77ohc2j869faqpmj2.png",
    alt: "A women doing weight lifting",
  },
  RockPerformance: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696324874/1%20homepage/d%20performance/zywynrhbrbre6ebu9oea.png",
    alt: "A women getting trained by a gym trainer",
  },
  RockHome: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1608704293/1%20homepage/a%20section%201/A_group_of_people_working_out_in_a_gym_j1uxeg.jpg",
    alt: "A group of people working out in a gym",
  },
  Caraousal: [
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696319043/1%20homepage/b%20Section%202/pebjm25i1gsy5yy18caf.png",
      alt: "A women lifting weight in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696319042/1%20homepage/b%20Section%202/i732ftfwramwmtx7fnps.png",
      alt: "A women doing pull up workout",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696319043/1%20homepage/b%20Section%202/o3glt5xlk87xazzcg95n.png",
      alt: "A women lifting dubells in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696319043/1%20homepage/b%20Section%202/haq2stad8i1tumzh23z1.png",
      alt: "A man lifting weight in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696319042/1%20homepage/b%20Section%202/rargfrxlq6kktzybef6j.png",
      alt: "A man lifting weight in a gym",
    },
  ],
};

const RockFitnessPage = {
  Header: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696416364/2%20fitness/Header/Header_dpaivk.png",
    alt: "A man is working out",
  },
  Energy: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696328937/2%20fitness/section%203/j3g1xz9smhaticmfw3vg.png",
    alt: "A man lifting weight in a gym",
  },
  Performance: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696329201/2%20fitness/section%203/sdofau66yqmtbuxfqb1f.png",
    alt: "A women lifting weight in a gym",
  },
  RockFitness1: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696327662/2%20fitness/section%202/upww99mrda1kg7xrhk8g.png",
    alt: "A women looking in a mirror lifiting kettlebell in a gym",
  },
  RockFitness2: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696327667/2%20fitness/section%202/hiprngp5upay4cekj8bv.png",
    alt: "Two girls are having a health drink",
  },
  RockFitness3: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696327668/2%20fitness/section%202/mqnddzpdg0a35pzll90v.png",
    alt: "A women choosing a perfect weight",
  },
  RockFitness4: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696327669/2%20fitness/section%202/p5vjlc0gdygy30tngoxw.png",
    alt: "A man and women working out together",
  },
  RockFitness5: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696327669/2%20fitness/section%202/pt3xhedyp0kvnlzkfxq6.png",
    alt: "A women working out",
  },
};

const RockFacilityPage = {
  HeroImage: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/fl_progressive,f_auto,q_auto/v1626850990/Our%20Facility/Hero/Facility_v6c703.png",
    alt: "Rock Facility Gym Area",
  },
  ImageGallary: [
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052266/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-001-001-DJI_0136HDR-4200x2360-300dpi_ko12kd.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052262/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-004-002-DSC06408HDREdit-4200x2806-300dpi_b2irlv.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052305/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-002-003-DSC06395HDREdit-4200x2814-300dpi_oxseli.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052281/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-003-004-DSC06401HDREdit-4200x2806-300dpi_pdkb82.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052318/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-025-017-DSC06541HDREdit-4200x2806-300dpi_dxzwfv.jpg",
      alt: "Rock Facility - Reception",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052314/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-022-028-DSC06521HDREdit-4200x2806-300dpi_uimtt2.jpg",
      alt: "Rock Facility - Equipments Cycle",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052313/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-014-013-DSC06470HDREdit-4200x2806-300dpi_dxria3.jpg",
      alt: "Rock Facility - Equipments Lifting Machine",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052309/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-018-020-DSC06496HDREdit-4200x2806-300dpi_zjdmje.jpg",
      alt: "Rock Facility - Washroom Area",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052308/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-020-025-DSC06508HDREdit-4200x2806-300dpi_tla8zi.jpg",
      alt: "Rock Facility - Main Area 2",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052305/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-010-012-DSC06446HDREdit-4200x2806-300dpi_uusncx.jpg",
      alt: "Rock Facility - Common Area",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052281/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-017-018-DSC06490HDREdit-4200x2806-300dpi_gzp37n.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052280/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-011-011-DSC06452HDREdit-4200x2806-300dpi_kelx4n.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052279/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-007-008-DSC06428HDREdit-4200x2807-300dpi_a1left.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1627052269/Our%20Facility/Image%20Gallery%20/2206_31st_St_Queens_NY_11105-print-005-006-DSC06416HDREdit-4200x2806-300dpi_qoenju.jpg",
      alt: "Rock Facility - Equipments Dumble",
    },
  ],
};

const RockPerformancePage = {
  Membership: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1608704639/3%20performance/section%203/A_women_trainer_training_a_women_in_a_gym_olvw3o.jpg",
    alt: "A women trainer training a women in a gym",
  },
  BookSession: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1608704632/3%20performance/section%205/Two_women_lifiting_a_weight_in_a_gym_wam4fl.jpg",
    alt: "Two women lifiting a weight in a gym",
  },
  OurTrainers: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1608704647/3%20performance/section%204/A_gym_trainer_training_a_group_of_people_in_gym_tehwac.jpg",
    alt: "A gym trainer training a group of people in gym",
  },
  Caraousal: [
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1608704638/3%20performance/section%202/A_man_and_women_doing_a_plank_in_a_gym_epjvx0.jpg",
      alt: "A man and women doing a plank in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696415852/3%20performance/section%202/Carousel_2-min_ks6smg.jpg",
      alt: "A women workingout in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696415856/3%20performance/section%202/Carousel_3-min_wxyakn.jpg",
      alt: "A man lifting a weight in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696415835/3%20performance/section%202/Carousel_4-min_olhvtq.png",
      alt: "Two women workingout in a gym",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696415841/3%20performance/section%202/Carousel_5-min_zvexmk.jpg",
      alt: "A women doing workout while looing in a mirror",
    },
  ],
};

const RockClassesPage = {
  HeroImage: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1696325613/Classes/Section1/f4laay6clibaveh42nya.png",
    alt: "A man trainer training a women in a gym",
  },
  Zoomba: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1626352338/Classes/Section2/Zumba_nq14mr.svg",
    alt: "A Women Doing Zoomba",
  },
  Boxing: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1626352338/Classes/Section2/Boxing_sq0ita.svg",
    alt: "Two Boxing Gloves",
  },
  Hiit: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1626352339/Classes/Section2/HIIT_uvwjzx.svg",
    alt: "A Women Doing HIIT",
  },
};

const RockSafetyPage = {
  SocialDistance: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1608704799/4%20safety/2-social-distancing-image_wtxp8s.svg",
    alt: "Physical Distancing",
  },
  PersonMask: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,h_0.5,q_auto,w_0.5/v1608704799/4%20safety/3-person-mask-image_kmm2fb.svg",
    alt: "Personal Protective Equipment",
  },
  TempCheck: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,h_0.5,q_auto,w_0.5/v1608704799/4%20safety/4-temp-check-image_k7e31c.svg",
    alt: "Temperature Check",
  },
  AirFiltration: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/v1615200854/4%20safety/air_filternation_image_tvntqd.svg",
    alt: "Air Filtration",
  },
  Sanitization: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/v1615200854/4%20safety/sanitization_image_dbkjxo.svg",
    alt: "Sanitization",
  },
  WashHand: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,h_0.5,q_auto,w_0.5/v1608704801/4%20safety/5.1wash-hand_aha1b1.svg",
    alt: "Wash your hands for 20 seconds",
  },
  AvoidEye: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,h_0.5,q_auto,w_0.5/v1608704801/4%20safety/5.2avoid-eye_ncrpx1.svg",
    alt: "Avoid touching your eyes, nose and mouth",
  },
  CoverMouth: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,h_0.5,q_auto,w_0.5/v1608704800/4%20safety/5.3cover-mouth_loamct.svg",
    alt: "Cover your mouth while coughing or sneezing",
  },
  CleanEquipment: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,h_0.5,q_auto,w_0.5/v1608704799/4%20safety/5.4clean-equipment_yfiipp.svg",
    alt: "clean and disInfect after using any equipments",
  },
};

const RockPackagePage = {
  PackageHeader: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1626861747/5%20packages/section%201/Packages_xe2zuh.png",
    alt: "Rock gym staff providing customer support on a phone",
  },
  PackageBackground1: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696326616/5%20packages/section%202/sqhfp42dah4bgrzwmdtw.jpg",
    alt: "A women lifiting weight in a gym",
  },
  PackageBackground2: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696326755/5%20packages/section%203/yxc39mcx97utckbejmik.jpg",
    alt: "A gym trainer training one person in a gym",
  },
  PackageBackground3: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,h_0.5,q_auto,w_0.5/v1608720282/5%20packages/section%204/A_gym_trainer_training_a_group_of_people_in_a_gym_pdxhfw.jpg",
    alt: "A gym trainer training a group of people in a gym",
  },
};

const RockBarPage = {
  Header: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696330065/Rockbar/Header/rc3pbx8mzydmeskf5yrj.png",
    alt: "A women holding Rockbars product",
  },
  Section1: {
    url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696330200/Rockbar/Section1/a20kxooqdpvbmczrk4ab.png",
    alt: "Rockbar Product",
  },
  Caraousal: [
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696413255/Rockbar/Carausel/Carousel_1_d8nshl.png",
      alt: "Three womens are discussing in rock bar",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696413225/Rockbar/Carausel/Carousel_2_supybz.png",
      alt: "A women in rockbar",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696413243/Rockbar/Carausel/Carousel_3_dnewhx.png",
      alt: "Two womens in rock bar",
    },
  ],
  ImageGallary: [
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696330310/Rockbar/Section2/s74memoogmuv4bqz58fm.png",
      alt: "Rockbar store",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696330306/Rockbar/Section2/yekixumvawjqrj5ktpaa.png",
      alt: "Rockbar Products",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696415149/Rockbar/Section2/747B298F-F322-494A-A58B-74A56D69CDDD_1_ejsgxm.png",
      alt: "Rockbar Product",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696330306/Rockbar/Section2/weatxeu11pmowiglidwb.png",
      alt: "Rockbar products",
    },
    {
      url: "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,f_jpg,fl_progressive,q_auto/v1696415184/Rockbar/Section2/IMG-2515_1_cjinvs.png",
      alt: "Rockbar Product",
    },
  ],
};

export {
  RockHomepage,
  RockFitnessPage,
  RockFacilityPage,
  RockPerformancePage,
  RockSafetyPage,
  RockPackagePage,
  RockClassesPage,
  RockBarPage
};
