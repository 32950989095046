import React from "react";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";

const CardLeft = ({
  text,
  link,
  href,
  para,
  image,
  content,
  bgAlt,
  outerLink,
  timing,
}) => {
  return (
    <div>
      <div className="card-wrapper-left">
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 order-1">
                <div className="img-wrapper">
                  <div className="mobile-img">
                    <div className="main-image">
                      <img src={image} alt={bgAlt} />
                    </div>
                  </div>

                  <div className="desktop-img">
                    <Parallax bgImage={image} bgImageAlt={bgAlt} strength={120}>
                      <div className="main-image"></div>
                    </Parallax>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 order-2">
                <div className="text-wrapper">
                  <h1>{text}</h1>
                  <p>{para}</p>

                  {timing && (
                    <div className="class_timing">
                      <p className="timing_title">Timings</p>
                      <ul>
                        {timing.map((e, i) => (
                          <li key={i}>
                            <p>{e.time}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {link && (
                    <Link className="card_link" to={href}>
                      {link}
                    </Link>
                  )}
                  {outerLink && (
                    <a className="card_link" href={href} target="_blank">
                      {outerLink}
                    </a>
                  )}
                  <div className="content-wrapper">{content}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLeft;
