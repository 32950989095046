import React, { Fragment } from "react";
import CommonPara from "../Common-para";
import Nav from "../Nav";
import RunnningText from "../RunningText";
import CardRight from "../CardRight";
import CardLeft from "../CardLeft";
import Footer from "../Footer";
import JoinToday from "../JoinToday";
import { Parallax } from "react-parallax";
import ArrowAnimation from "../ArrowAnimation";
import { RockClassesPage } from "../Images/rockImages";

const classes = [
  {
    img_position: "left-card",
    title: "Core + Abs Class",
    para: "This class will concentrate on strengthening your core muscles. Including your abdominal muscles, back muscles and the muscles around the pelvis. This includes twisting, crunches, planks, among other core exercises.",
    timing: [
      {
        time: "MONDAY - 8 AM",
      },
      {
        time: "WEDNESDAY - 8 AM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1654247988/Classes/Section2/Vinyasa_Yoga_qj8nrr.svg",
    img_alt: "Two Boxing Gloves",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "right-card",
    title: "Boxing",
    para: "Our ROCK Box classes are designed to improve your cardio, sculpt your muscle, burn calories, and relieve stress.",
    timing: [
      {
        time: "MONDAY - 6:30 PM",
      },
      {
        time: "WEDNESDAY - 6:30 PM",
      },
      {
        time: "SATURDAY - 11 AM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1626352338/Classes/Section2/Boxing_sq0ita.svg",
    img_alt: "Two Boxing Gloves",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "left-card",
    title: "Barbell Blast",
    para: "Barbell Blast is a class designed to help the novice gym goer learn the basics of barbell lifting. Coach Allison will guide members through various lifts each week focusing on form, safety, muscular conditioning, mobility and balance. Come ready to sweat, and prepared to leave empowered, educated and feeling strong. All levels are welcome!",
    timing: [
      {
        time: "TUESDAY - 6 PM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1696403634/Classes/Section2/Bar_bell_Blast_kjgrsn.svg",
    img_alt: "Barbell Blast with Allison",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "right-card",
    title: "Rock Body Boot Camp",
    para: "Rock Body Bootcamp  combines the best of cardiovascular and strength training.",
    timing: [
      {
        time: "TUESDAY - 8 AM",
      },
      {
        time: "FRIDAY - 8 AM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1645701660/Classes/Section2/Functional_Fitness_zdxhjc.svg",
    img_alt: "Functional Fitness",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "left-card",
    title: "HIIT",
    para: "High intensity classes to raise your heart rate, increase your pulse rate and rev up your metabolism.",
    timing: [
      {
        time: "TUESDAY - 6 PM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1626352339/Classes/Section2/HIIT_uvwjzx.svg",
    img_alt: "A Women Doing HIIT",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "right-card",
    title: "Dance",
    para: "Come dance your stress away with this full body workout combining cardio and strength intervals as well as a killer playlist. No matter if you are an advanced dancer or have two left feet this class is for everyone! You will leave this class feeling the burn and ready to party!",
    timing: [
      {
        time: "WEDNESSDAY - 7 PM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1696412630/Classes/Section2/Dance_trtugv.svg",
    img_alt: "Kick Boxing",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "left-card",
    title: "Zumba",
    para: "Zumba targets lots of different muscle groups at once for total body toning. Boosts your heart health. You not only get aerobic benefits (it really gets your heart rate up), you also get anaerobic benefits – the kind that help you maintain a good cardiovascular respiratory system. Helps you de-stress.",
    timing: [
      {
        time: "THURSDAY - 7 PM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1626352338/Classes/Section2/Zumba_nq14mr.svg",
    img_alt: "A Women Doing Zoomba",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "right-card",
    title: "BEAT the HEAT",
    para: "This cardio class focuses on running and light to medium weight training to give you a great burn, increase lean body mass and make you feel more alive than ever!",
    timing: [
      {
        time: "SATURDAY - 10 AM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1645701660/Classes/Section2/Beat_the_heat_lh23ly.svg",
    img_alt: "BEAT the HEAT",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "left-card",
    title: "Glutes",
    para: "This class focuses on movements that target and strengthen the muscles in and around your legs and glutes.",
    timing: [
      {
        time: "SATURDAY - 12 PM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1696403634/Classes/Section2/Glutes_qlnt4a.svg",
    img_alt: "Glutes with Stephanie",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
  {
    img_position: "right-card",
    title: "Correctional Core",
    para: "In this class we will discover our imbalances and use specific isometric exercises to target under active muscles as well as use mobility and yoga to free up overactive muscles! The objective of this class is to find balance in your body! With the correctional elements in this class you will be able to strengthen your mind-body connection and overall sense of grounding.",
    timing: [
      {
        time: "SUNDAY - 10 AM",
      },
    ],
    img_url:
      "https://res.cloudinary.com/rock-gyms-com/image/upload/c_scale,fl_progressive,q_auto/v1673339726/Classes/Section2/Power_Hour_thlfw4.svg",
    img_alt: "Power Hour",
    link: "https://www.joinmyhealthclub.com/Competejol/510512",
  },
];

const Classes = () => {
  return (
    <>
      <div className="classes">
        <div className="fitness">
          <div className="fitness-container">
            <div className="hero-container">
              <Nav />
              <section className="hero-section row">
                <div className="col-xl-7 col-lg-8 col-md-8 col-10 mx-auto">
                  <div className="hero-text">
                    <p>ROCK CLASSES</p>
                    <h1>
                      Our <br /> Classes
                    </h1>
                    <div className="hero-join-today">
                      <ArrowAnimation
                        text={"START NOW"}
                        isAnimate={true}
                        isModal={true}
                      />
                    </div>
                  </div>
                  <div className="hero-img">
                    <video
                        playsInline
                        autoPlay
                        className="vid"
                        loop
                        muted
                        poster={RockClassesPage.HeroImage.url}
                      >
                        <source
                          src="https://res.cloudinary.com/rock-gyms-com/video/upload/q_auto/f_auto/v1696495412/Classes/Section1/jqaakcn3mxyhpanwg2uh.mp4"
                          type="video/mp4"
                        ></source>
                      </video>
                  </div>
                </div>
              </section>
            </div>

            <section className="paragraph-section common-padding">
              <CommonPara
                text={
                  "Rock Fitness provides a wide range of complimentary classes available to all our members."
                }
              />
            </section>

            <section className="safety-running-section common-padding">
              <RunnningText text={"YOU ARE ESSENTIAL"} />
            </section>

            {classes.map((e, index) =>
              e.img_position === "left-card" ? (
                <section className="left-card common-padding">
                  <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                      <CardLeft
                        text={e.title}
                        para={e.para}
                        timing={e.timing}
                        image={e.img_url}
                        bgAlt={e.img_alt}
                        outerLink={
                          <>
                            REGISTER NOW
                            <div className="arrow-position ">↗</div>
                          </>
                        }
                        href={e.link}
                      />
                    </div>
                  </div>
                </section>
              ) : (
                <section className="right-card common-padding">
                  <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto ">
                      <CardRight
                        text={e.title}
                        para={e.para}
                        timing={e.timing}
                        image={e.img_url}
                        bgAlt={e.img_alt}
                        outerLink={
                          <>
                            REGISTER NOW
                            <div className="arrow-position ">↗</div>
                          </>
                        }
                        href={e.link}
                      />
                    </div>
                  </div>
                </section>
              )
            )}

            <section className="join-today-section common-padding">
              <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                  <JoinToday />
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Classes;
