import React, { useEffect } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const FitnessSwiper = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <Swiper
        spaceBetween={5}
        slidesPerView={1.3}
        navigation
        pagination={{ type: "fraction" }}
        centerInsufficientSlides={true}
        breakpoints={{
          768: {
            spaceBetween: 30,
            slidesPerView: 1.2,
          },
        }}
        onReachEnd={() => {
          // debugger;
          // let currentPostion = +$(".swiper-wrapper")[0]
          //   .style.transform.split("(")[1]
          //   .split(",")[0]
          //   .split("px")[0];
          // var xChangeBy =
          //   $(".swiper-container")[0].offsetWidth -
          //   $(".swiper-slide")[0].swiperSlideSize;
          // var xtranslate = currentPostion - xChangeBy;
          // $(".swiper-wrapper")[0].style.transform =
          //   "translate3d(" + xtranslate + "px, 0px, 0px)";
          // console.log($(".swiper-container")[0].offsetWidth);
          // console.log($(".swiper-slide")[0].swiperSlideSize);
        }}
      >
        {props.image.map((img) => (
          <SwiperSlide>
            <img src={img.url} alt={img.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default FitnessSwiper;
