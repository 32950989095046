import React from "react";
import CommonPara from "../Common-para";
import Nav from "../Nav";
import Footer from "../Footer";
import JoinToday from "../JoinToday";
import PackagesBanner from "../PackagesBanner";
import PackagesBannerDesktop from "../PackageBannerDesktop";
import { Parallax } from "react-parallax";
import { RockPackagePage } from "../Images/rockImages";
import ArrowAnimation from "../ArrowAnimation";

const Packages = () => {
  return (
    <>
      <div className="packages">
        <div className="packages-container">
          <div className="hero-container">
            <Nav />
            <section className="hero-section row">
              <div className="col-xl-7 col-lg-8 col-md-8 col-10 mx-auto">
                <div className="hero-text">
                  <p>ROCK PACKAGES</p>
                  <h1>
                    Memberships <br />
                    starting as low
                    <br className="d-none d-xl-inline" /> as $59/month
                  </h1>
                  <div className="hero-join-today">
                    <ArrowAnimation
                      text={"START NOW"}
                      isAnimate={true}
                      isModal={true}
                    />
                  </div>
                </div>
                <div className="hero-img bg">
                  <div className="mobile-img">
                    <div className="set-height">
                      <img
                        src={RockPackagePage.PackageHeader.url}
                        alt={RockPackagePage.PackageHeader.alt}
                      />
                    </div>
                  </div>

                  <div className="desktop-img">
                    <Parallax
                      bgImage={RockPackagePage.PackageHeader.url}
                      strength={120}
                      bgImageAlt={RockPackagePage.PackageHeader.alt}
                    >
                      <div className="set-height"></div>
                    </Parallax>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="paragraph-section common-padding">
            <CommonPara
              text={
                <>
                  Check out the packages ROCK{" "}
                  <br className="d-none d-xl-inline d-lg-inline" /> has to
                  offer.
                </>
              }
            />
          </section>

          <section className="banner-section common-padding hide-for-desktop">
            <div className="banner1">
              <PackagesBanner
                image={RockPackagePage.PackageBackground1.url}
                title={
                  <>
                    Rock Fitness & <br /> Lifestyle Access
                  </>
                }
                cardTitle={<>Access to our state of the art facility</>}
                cardPara={
                  <>
                    Special discount for Police, Fireman, EMS, Teachers,
                    Spouse, Senior Citizens
                  </>
                }
                price={<>From $59/month*</>}
                displaDiscountIcon={true}
                bgAlt={RockPackagePage.PackageBackground1.alt}
                linkText="JOIN NOW"
              />
            </div>
            <div className="banner2">
              <PackagesBanner
                image={RockPackagePage.PackageBackground2.url}
                title={
                  <>
                    Individual <br />
                    Personal Training
                  </>
                }
                cardTitle={
                  <>
                    Let our expert coaches build you a custom plan suitable for
                    your needs.
                  </>
                }
                cardPara={<>Special discount for Couples and on 10 pack</>}
                displaDiscountIcon={true}
                bgAlt={RockPackagePage.PackageBackground2.alt}
                linkText="CONTACT US"
                isModal={true}
              />
            </div>
          </section>

          <section className="banner-section common-padding hide-for-mobile">
            <PackagesBannerDesktop />
          </section>

          <section className="join-today-section common-padding">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                <JoinToday />
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Packages;
