import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";

import {
  staggerText,
  staggerReveal,
  fadeInUp,
  handleHover,
  handleHoverExit,
  staggerRevealClose,
} from "./Animations";
import SocialFollows from "./SocialFollows";
import ArrowAnimation from "./ArrowAnimation";

const Hamburger = ({ state, handleMenu }) => {
  // Create varibles of our dom nodes
  let menuLayer = useRef(null);
  let reveal1 = useRef(null);
  let reveal2 = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);
  let line5 = useRef(null);
  let line6 = useRef(null);
  let line7 = useRef(null);
  let line8 = useRef(null);
  let info = useRef(null);

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      staggerRevealClose(reveal2, reveal1);
      // Set menu to display none
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
      //Allow menu to have height of 100%
      gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
        height: "100%",
        width: "100%",
      });
      staggerReveal(reveal1, reveal2);
      fadeInUp(info);
      staggerText(line1, line2, line3, line4, line5, line6, line7, line8);
    }
  }, [state]);

  return (
    <div ref={(el) => (menuLayer = el)} className="hamburger-menu">
      <div
        ref={(el) => (reveal1 = el)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={(el) => (reveal2 = el)} className="menu-layer">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="menu-links row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                <nav>
                  <ul>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line1 = el)}
                        to="/"
                      >
                        HOME
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line2 = el)}
                        to="/fitness"
                      >
                        FITNESS
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line3 = el)}
                        to="/facilities"
                      >
                        FACILITY
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line4 = el)}
                        to="/performance"
                      >
                        PERFORMANCE
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line5 = el)}
                        to="/packages"
                      >
                        PACKAGES
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line6 = el)}
                        to="/classes"
                      >
                        CLASSES
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line7 = el)}
                        to="/rockbar"
                      >
                        ROCK BAR
                      </Link>
                    </li>
                    <li>
                      <a
                        onMouseEnter={(e) => handleHover(e)}
                        onMouseOut={(e) => handleHoverExit(e)}
                        ref={(el) => (line8 = el)}
                        onClick={handleMenu}
                        data-toggle="modal"
                        data-target="#staticBackdrop"
                      >
                        CONTACT US
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <div ref={(el) => (info = el)} className="info">
                  <div className="join-now" onClick={handleMenu}>
                    <ArrowAnimation
                      text={"START NOW"}
                      isAnimate={true}
                      isModal={true}
                    />
                  </div>

                  <div className="footer-links">
                    <div className="footer-title">CONNECT</div>
                    <div className="social">
                      <SocialFollows />
                    </div>
                  </div>

                  <div className="footer-links">
                    <div className="footer-title">GET IN TOUCH</div>
                    <div>
                      <p>
                        <a href="tel:+1-718-204-1400">+1 718 204 1400</a>
                      </p>
                    </div>
                    <div>
                      <p>
                        <a href="mailto:info@rock-gyms.com">
                          info@rock-gyms.com
                        </a>
                      </p>
                    </div>
                    <div>
                      <a
                        href="https://goo.gl/maps/n73BS6jGVggsRYkt8"
                        target="_blank"
                      >
                        <p>
                          22-06 31st Street,
                          <br />
                          Astoria,NY - 11105 <br />
                          United States
                        </p>
                      </a>
                    </div>
                  </div>

                  <div className="footer-links footer-copywrite">
                    <div className="inline">
                      <p>Site by </p> &nbsp;
                      <a href="https://littleplanet.studio/" target="_blank">
                        Little Planet
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
