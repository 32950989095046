import React from "react";

const CommonPara = ({ text }) => {
  return (
    <>
      <div className="common-para row">
        <div className="col-xl-7 col-lg-8 col-md-9 col-10 mx-auto">
          <h1>{text}</h1>
        </div>
      </div>
    </>
  );
};

export default CommonPara;
