import React, { Fragment } from "react";
import Nav from "../Nav";
import RunnningText from "../RunningText";
import Footer from "../Footer";
import JoinToday from "../JoinToday";
import { Parallax } from "react-parallax";
import ArrowAnimation from "../ArrowAnimation";
import { RockFacilityPage } from "../Images/rockImages";
import CommonPara from "../Common-para";

const Facility = () => {
  return (
    <>
      <div className="safety">
        <div className="fitness">
          <div className="fitness-container">
            <div className="hero-container">
              <Nav />
              <section className="hero-section row">
                <div className="col-xl-7 col-lg-8 col-md-8 col-10 mx-auto">
                  <div className="hero-text">
                    <p>ROCK FACILITY</p>
                    <h1>
                      Our World <br /> Class <br />Facility
                    </h1>
                    <div className="hero-join-today">
                      <ArrowAnimation
                        text={"START NOW"}
                        isAnimate={true}
                        isModal={true}
                      />
                    </div>
                  </div>
                  <div className="hero-img">
                    <div className="mobile-img">
                      <div className="set-height">
                        <img
                          src={RockFacilityPage.HeroImage.url}
                          alt={RockFacilityPage.HeroImage.alt}
                        />
                      </div>
                    </div>

                    <div className="desktop-img">
                      <Parallax
                        bgImage={RockFacilityPage.HeroImage.url}
                        bgImageAlt={RockFacilityPage.HeroImage.alt}
                        strength={120}
                      >
                        <div className="set-height"></div>
                      </Parallax>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section className="paragraph-section common-padding">
              <CommonPara
                text={
                  "ROCK's comprehensive strategy implements rigid guidelines and procedures such as routine cleaning and sanitation procedures to ensure your safety."
                }
              />
            </section>

            <section className="safety-running-section common-padding">
              <RunnningText text={"WORLD CLASS FACILITY"} />
            </section>

            <section className="facility-image-gallery common-padding">
              <div className="row">
                {RockFacilityPage.ImageGallary.map((image, i) => (
                  <div
                    className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto mb-3 mb-lg-4"
                    key={i}
                  >
                    <img
                      src={image.url}
                      alt={image.alt}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </div>
            </section>

            <section className="join-today-section common-padding">
              <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-10 col-10 mx-auto">
                  <JoinToday />
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Facility;
