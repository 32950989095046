import React from "react";

const RunnningText = ({ text }) => {
  return (
    <>
      <section>
        <div className="scroll text">
          <div>&nbsp; &nbsp; {text} </div>
          <div>&nbsp; &nbsp; {text} </div>
        </div>
      </section>
    </>
  );
};

export default RunnningText;
