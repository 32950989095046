import { React, useEffect } from "react";
import Discount from "../assets/coming_soon/discount-icon.svg";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import $ from "jquery";
import ArrowAnimation from "./ArrowAnimation";
import { RockPackagePage } from "./Images/rockImages";
// import {bodyScrollBar} from "../js/GsapScroll";

//import ScriptTag from 'react-script-tag';
const plugins = [ScrollToPlugin];
const PackagesBannerDesktop = () => {
  // let scroller = useRef(null);
  // let pageSection = useRef([]);
  // let blackSection = useRef(null);
  // let mainImage = useRef(null);
  // let navigationLink = useRef(null);
  var secTop = 0;
  useEffect(() => {
    setTimeout(() => {
      secTop = cumulativeOffset($(".black")[0]).top;
      toshiba();
    }, 200);
  }, []);

  const cumulativeOffset = function (element) {
    var top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  };

  const toshiba = () => {
    // let bodyScrollBar = Scrollbar.init(
    //   document.getElementsByClassName("scroller")[0],
    //   {
    //     damping: 0.1,
    //     delegateTo: document,
    //   }
    // );
    //intialized the gsap
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    /**
     * Added scroller proxy
     */
    // ScrollTrigger.scrollerProxy(".scroller", {
    //   scrollTop(value) {
    //     if (arguments.length) {
    //       bodyScrollBar.scrollTop = value;
    //     }
    //     return bodyScrollBar.scrollTop;
    //   },
    // });
    // bodyScrollBar.addListener(ScrollTrigger.update);

    /**
     * Pinned content
     */
    gsap.set(".page-section", {
      zIndex: (p, target, targets) => targets.length - p,
    });

    var pages = gsap.utils.toArray(".page-section:not(.purple)");

    pages.forEach((page, p) => {
      var pl = gsap.timeline({
        scrollTrigger: {
          id: "section",
          trigger: "section.black",
          // scroller: ".scroller",
          start: () => "top -" + window.innerHeight * (p + 0.5),
          end: () => "+=" + window.innerHeight,
          scrub: true,
          toggleActions: "play reverse play reverse",
          invalidateOnRefresh: true,
        },
      });

      pl.to(page, { visibility: "hidden", opacity: 0, height: 0 });
    });

    //arrays for images
    gsap.set(".main-images", {
      zIndex: (i, target, targets) => targets.length - i,
    });

    var images = gsap.utils.toArray(".main-images:not(.end-img)");

    images.forEach((image, i) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          id: "bg",
          trigger: "section.black",
          // scroller: ".scroller",
          start: () => "top -" + window.innerHeight * (i + 0.5),
          end: () => "+=" + window.innerHeight,
          scrub: true,
          toggleActions: "play reverse play reverse",
          invalidateOnRefresh: true,
        },
      });

      tl.to(image, { visibility: "hidden", opacity: 0 });
    });

    //array for links
    gsap.set(".navigation__link", {
      // zIndex: (i, target, targets) => targets.length - i,
    });

    var texts = gsap.utils.toArray(".navigation__link");

    texts.forEach((text, i) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          id: "text",
          markers: false,
          trigger: "section.black",
          // scroller: ".scroller",
          start: () => "top -" + window.innerHeight * (i + 0.55),
          end: () => "+=" + window.innerHeight,
          scrub: true,
          toggleActions: "play reverse play reverse",
          invalidateOnRefresh: true,
        },
        onStart: () => {},
      });

      tl.to(text, {
        color: "#ffffff",
        borderLeft: "10px solid #FCDF00",
      }).to(text, {
        color: "#86888E",
        borderLeft: "10px solid rgba(0, 0, 0, 0)",
        cursor: "pointer",
      });

      var activeSection = text.getAttribute("name");
      text.addEventListener("click", function (e) {
        e.preventDefault();
        let s =
          secTop + (cumulativeOffset(text).top + text.scrollHeight) * 2.55;
        // let s = document.getElementById()
        // console.log(
        //   secTop,
        //   secTop + cumulativeOffset(text).top,
        //   text.scrollHeight,
        //   s
        // );
        window.scroll(0, s);

        // $(texts).css("color", "#86888E");
        // $(texts).css("border-left", "10px solid rgba(0, 0, 0, 0)");
        // text.style.color = "#ffffff";
        // text.style.borderLeft = "10px solid #FCDF00";
        // console.log(text.id);
        // gsap.to(window, { duration: 1, scrollTo: { y: "#" + text.id, offsetY: 500 } });

        // window.location.href="#"+text.id;

        // text.scrollIntoView();
        // window.scroll(0, window.innerHeight * (i + 3) + text.getBoundingClientRect().top);
        //Finds y value of given object
        // gsap.to(window, { duration: 1, scrollTo: "#"+text.id });
        // gsap.to(text, {duration:0.4, y:-200 });
      });
    });
    function findPos(obj) {
      debugger;
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [curtop];
      }
    }

    // let txx = gsap.timeline();

    // $(texts).on("click", (t) => {
    //   t.preventDefault();
    // gsap.to('.scroller', {
    //   duration: 1,
    //   scrollTo: "#"+t.target.id
    // });

    // $(texts).css("color", "#86888E");
    // $(texts).css("border-left", "10px solid rgba(0, 0, 0, 0)");

    // $(t.target).css("color", "#ffffff");
    // $(t.target).css("border-left", "10px solid #FCDF00");
    // $(".page-section:not(.purple)").css("visibility", "hidden");
    // $(".page-section:not(.purple)").css("opacity", "0");

    // $(".main-images:not(.end-img)").css("visibility", "hidden");
    // $(".main-images:not(.end-img)").css("opacity", "0");

    // let sectionID = t.target.attributes.name.value;
    // console.log("clcik", t);
    // // gsap.to(document.getElementsByClassName("scroller")[0], {duration: 2, scrollTo:"#"+sectionID});
    // // gsap.to($("#"+sectionID), {duration: 2, y:"#"+sectionID});
    // $("#" + sectionID).css("visibility", "visible");
    // $("#" + sectionID).css("opacity", "1");

    // $("div[name='"+sectionID+"']").css("visibility", "visible");
    // $("div[name='"+sectionID+"']").css("opacity", "1");
    // TweenMax.to(window, 1, {scrollTo:"#"+sectionID+"link"});
    // gsap.to(window, {duration: 2, y:"#"+sectionID});
    // gsap.to(window, {duration:1, scrollTo: "#pagesec"+sectionID });
    // TweenMax.to(window, 0.5, {scrollTo: {y: $("#"+sectionID+"link").offset().top}})
    // });

    //created the pinnes
    ScrollTrigger.create({
      id: "main",
      trigger: "section.black",
      scrub: true,
      markers: false,
      pin: true,
      start: () => "top",
      end: () => "+=" + (images.length + 1) * window.innerHeight,
      invalidateOnRefresh: true,
    });
  };

  return (
    <>
      <section className="black">
        <div className="bgimages">
          <div className="main-images" style={{ visibility: "hidden" }}></div>
          <div className="main-images" name="1">
            <img
              src={RockPackagePage.PackageBackground1.url}
              alt={RockPackagePage.PackageBackground1.alt}
            />
          </div>
          <div className="main-images end-img" name="2">
            <img
              src={RockPackagePage.PackageBackground2.url}
              alt={RockPackagePage.PackageBackground2.alt}
            />
          </div>

          {/* <div className="main-images end-img" name="3">
            <img
              src={RockPackagePage.PackageBackground3.url}
              alt={RockPackagePage.PackageBackground3.alt}
            />
          </div> */}
        </div>
        <nav className="navigation" id="mainNav">
          <div className="navigation__link" name="1" id="link1">
            Rock Fitness & <br /> Lifestyle Access
          </div>
          <div className="navigation__link" name="2" id="link2">
            Individual <br />
            Personal Training
          </div>
          {/* <div className="navigation__link" name="3" id="link3">
            Semi-Private Personal <br />
            Training + Lifestyle Access
          </div> */}
        </nav>
        <div className="p-wrap">
          <div
            className="page-section"
            style={{ visibility: "hidden" }}
            id="0"
          ></div>
          <div className="page-section discount1" id="pagesec1">
            <Card
              cardTitle={<>Access to our state of the art facility</>}
              cardPara={
                <>
                  Special discount for Police, Fireman, EMS, Teachers, Spouse,
                  Senior Citizens
                </>
              }
              price={<>From $59/month*</>}
              displaDiscountIcon={true}
              linkText="JOIN NOW"
            />
            <div className="condition">*Conditions Apply</div>
          </div>
          <div className="page-section purple discount2" id="pagesec2">
            <Card
              cardTitle={
                <>
                  Let our expert coaches build you a custom plan suitable for
                  your needs.
                </>
              }
              cardPara={<>Special discount for Couples and on 10 pack</>}
              displaDiscountIcon={true}
              linkText="CONTACT US"
              isModal={true}
            />
            <div className="condition">*Conditions Apply</div>
          </div>
          {/* <div className="page-section purple discount2" id="pagesec3">
            <Card
              cardTitle={
                <>
                  Same great custom plan, along side a few amazing community
                  members
                </>
              }
              cardPara={<>Special prices for 2X and 3X per week plans</>}
              price={
                <>
                  Personalized training at a fraction of the cost. As low as
                  $28/session* <br />
                  <span>
                    Includes Your Unlimited Fitness & Lifestyle Membership
                  </span>
                </>
              }
              displaDiscountIcon={true}
              linkText="START NOW"
              // srcLink=""
              // target=""
              isAnimate={true}
            />
            <div className="condition">*Conditions Apply</div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default PackagesBannerDesktop;

const Card = ({
  cardTitle,
  cardPara,
  price,
  displaDiscountIcon,
  linkText,
  srcLink,
  target,
  isModal,
}) => {
  return (
    <>
      <div className="desktop-card-wrapper">
        <div className="card">
          <div className="card-content">
            <div>
              <div className="card-title">
                <h3>{cardTitle}</h3>
              </div>
              <div className="price">
                <p>{price}</p>
              </div>
              <div className="card-para">
                <p>{cardPara}</p>
                {displaDiscountIcon ? (
                  <div className="discount-img">
                    <img src={Discount} alt="Rock Discount Image" />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className="inquiry">
              <ArrowAnimation
                text={linkText}
                link={srcLink}
                target={target}
                isModal={isModal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
